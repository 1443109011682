.image-slider {
    width: 80%;
    margin: 20px auto;
  }
  
  .image-item {
    position: relative;
    width: 100%;
    height: auto;
  }
  
  .image-item img {
    width: 100%;
    height: auto;
    border-radius: 10px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  }
  
  .slick-dots {
    bottom: -30px;
    display: flex !important;
    justify-content: center;
    padding: 10px 0;
  }
  
  .slick-dots li button:before {
    font-size: 8px; /* Diminuir o tamanho da fonte das bolinhas */
    color: #000; /* Cor preta para as bolinhas */
  }
  
  .slick-dots li.slick-active button:before {
    color: #000; /* Cor preta para a bolinha ativa */
  }
  
  .slick-dots li {
    width: 8px; /* Ajustar largura das bolinhas */
    height: 8px; /* Ajustar altura das bolinhas */
  }
  
  .slick-prev:before,
  .slick-next:before {
    color: #000; /* Cor preta para as setas */
  }
  