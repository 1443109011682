/* src/App.css */
.App {
  text-align: center;
  background-color: #ffe6e6;
  min-height: 100vh;
  overflow-x: hidden;
  font-family: 'Arial', sans-serif;
  position: relative;
}

.App-header {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 20px;
}

.App-logo {
  height: 30vmin; /* Aumentar o tamanho da logo */
  pointer-events: none;
}

h1 {
  color: #000;
  margin-top: 20px;
  font-size: 2.5em;
  font-family: 'Lobster', cursive;
}

.description {
  color: #333;
  margin-top: 10px;
  font-size: 1em;
  max-width: 600px;
  text-align: center;
}

button {
  margin-top: 20px;
  padding: 15px 30px; /* Aumentar o padding dos botões */
  font-size: 18px; /* Aumentar o tamanho da fonte dos botões */
  cursor: pointer;
  background-color: #FF7F00; /* Laranja mais forte */
  color: white;
  border: none;
  border-radius: 5px;
  transition: background-color 0.3s ease;
  font-weight: bold;
  animation: pulse 2s infinite; /* Adicionar animação de pulsação */
}

button:hover {
  background-color: #FF6600; /* Laranja mais escuro ao passar o mouse */
}

.second-button {
  margin-top: 40px; /* Aumentar o espaço acima do segundo botão */
}

@keyframes pulse {
  0% {
    transform: scale(1);
  }
  50% {
    transform: scale(1.1);
  }
  100% {
    transform: scale(1);
  }
}

.slick-dots {
  bottom: -30px;
  display: flex !important;
  justify-content: center;
  padding: 10px 0;
}

.slick-dots li button:before {
  font-size: 8px; /* Diminuir o tamanho da fonte das bolinhas */
  color: #000; /* Cor preta para as bolinhas */
}

.slick-dots li.slick-active button:before {
  color: #000; /* Cor preta para a bolinha ativa */
}

.slick-dots li {
  width: 8px; /* Ajustar largura das bolinhas */
  height: 8px; /* Ajustar altura das bolinhas */
}

.slick-prev:before,
.slick-next:before {
  color: #000; /* Cor preta para as setas */
}

.description {
  color: #333;
  margin-top: 10px;
  font-size: 1em;
  max-width: 600px;
  text-align: left; /* Alinhar à esquerda */
  line-height: 1.5; /* Espaçamento entre linhas */
}

.contact-info {
  color: #333;
  margin-top: 20px; /* Aumentar o espaçamento */
  font-size: 1.0em; /* Aumentar o tamanho da fonte */
  max-width: 600px;
  text-align: center;
}
